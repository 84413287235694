import { BaseRoutes } from './baseRoutes';

export enum ROUTES {
  PERSONAL_INFO = 'datos-personales',
  AUTO_EXTERNAL = 'datos-vehiculo-externo',
  AUTO = 'vehiculo',
  INCOME = 'ingresos',
  PRE_QUALIFICATION = 'pre-calificacion',
  NIP = 'autorizacion',
  DOCUMENTS = 'documentos',
  CONTACT = 'contacto',
  REJECTED_THIRTY_DAYS = 'solicitud-existente',
  REFERENCE = 'referencias',
  IDENTITY_VERIFICATION = 'verificacion-identidad',
  SENT_DOCUMENTS = 'documentos-enviados',
  RECEIVED_REQUEST = 'documentos-sin-preaprobacion',
  REJECTED_REASONS = 'razones-de-rechazo',
  ADDRESS_INFO = 'domicilio',
  WELCOME = 'bienvenida',
  REDIRECT = 'redireccion',
}

const landingPage = process.env.REACT_APP_CREDITAS_AUTOFIN_LANDING_URL;

export const GO_TO_ROUTES = {
  AUTO_EXTERNAL: {
    toPersonalInfo: BaseRoutes.AUTOFIN_AUTO + ROUTES.PERSONAL_INFO,
    toHasAnApplication: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
  },
  WELCOME: {
    toPersonalInfo: BaseRoutes.AUTOFIN_AUTO + ROUTES.PERSONAL_INFO,
  },
  PERSONAL_INFO: {
    goToSuccess: BaseRoutes.AUTOFIN_AUTO + ROUTES.ADDRESS_INFO,
    goToRejectedDays: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
    goToRejectedReasons: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS,
  },
  ADDRESS_INFO: {
    goToSuccess: BaseRoutes.AUTOFIN_AUTO + ROUTES.AUTO,
    goToRejectedDays: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
    goToRejectedReasons: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS,
  },
  AUTO: {
    goToSuccess: BaseRoutes.AUTOFIN_AUTO + ROUTES.INCOME,
    goToRejectedDays: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
    goToRejectedReasons: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS,
  },
  INCOME: {
    goToSuccess: BaseRoutes.AUTOFIN_AUTO + ROUTES.NIP,
    goToRejectedDays: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
    goToRejectedReasons: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS,
  },
  NIP: {
    approved: BaseRoutes.AUTOFIN_AUTO + ROUTES.PRE_QUALIFICATION,
    denied: BaseRoutes.AUTOFIN_AUTO + ROUTES.RECEIVED_REQUEST,
  },
  PRE_QUALIFICATION: BaseRoutes.AUTOFIN_AUTO + ROUTES.RECEIVED_REQUEST,
  REFERENCE: BaseRoutes.AUTOFIN_AUTO + ROUTES.IDENTITY_VERIFICATION,
  IDENTITY_VERIFICATION: BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS,
  DOCUMENTS: BaseRoutes.AUTOFIN_AUTO + ROUTES.SENT_DOCUMENTS,
  RECEIVED_REQUEST: {
    toContinue: BaseRoutes.AUTOFIN_AUTO + ROUTES.IDENTITY_VERIFICATION,
  },
  REJECTED_REASONS: landingPage,
  REJECTED_URL: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
};

export const GO_TO_ROUTES_MELI = {
  ...GO_TO_ROUTES,
  ADDRESS_INFO: {
    goToSuccess: BaseRoutes.AUTOFIN_AUTO + ROUTES.INCOME,
    goToRejectedDays: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
    goToRejectedReasons: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS,
  },
};
