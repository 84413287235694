import { ROUTES } from './routes';

const imgUrl = `${process.env.PUBLIC_URL}/images`;

export const ArrayImg = {
  [ROUTES.WELCOME]: {
    desktop: `${imgUrl}/login/desktop-img.png`,
    mobile: `${imgUrl}/login/mobile-img.png`,
    alt: 'Imagen de registro',
  },
};
