/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getQueryParams } from '@creditasmx-utilities/helpers';

const axiosRequestConfiguration = (endpoint: string): AxiosRequestConfig => ({
  baseURL: `${process.env.REACT_APP_AUTO_ORCHESTRATOR_URL || ''}/${endpoint}`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getSteps = (): AxiosInstance => {
  const queryParams = getQueryParams();

  const leadIdQueryParams =
    queryParams.get('sessionId') || localStorage.getItem('publicAutofinLeadId');
  const endpoint = `public/leads/${leadIdQueryParams}/steps`;

  return Axios.create({
    ...axiosRequestConfiguration(endpoint),
    method: 'GET',
  });
};

