import { start, registerApplication, LifeCycles } from 'single-spa';
import Activity from './activityFns';
import { ROUTES, GO_TO_ROUTES, GO_TO_ROUTES_MELI } from './routes';
import { BaseRoutes } from './baseRoutes';
import { ArrayImg } from './imagesDictionary';
import { MFs, getReceivedRequestProps } from './microfrontendsRegister';

const queryString = window.location.search;
const queryParams = new URLSearchParams(queryString);
const isMeliCompany =
  queryParams.get('companyId') === process.env.REACT_APP_MELI_COMPANY_ID;
const goToRoutes = isMeliCompany ? GO_TO_ROUTES_MELI : GO_TO_ROUTES;

const registerApps = (): void => {
  registerApplication(
    'receivedRequest',
    () =>
      System.import(MFs.RECEIVED_REQUEST) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.receivedRequest,
    getReceivedRequestProps
  );
  registerApplication(
    'navBarSupport',
    () =>
      System.import(MFs.NAV_BAR_SUPPORT) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.navBarSupport,
    {
      domElementId: 'renderNavbarId',
      productName: 'publicAutofin',
      logoGoTo:
        BaseRoutes.AUTOFIN_AUTO +
        (isMeliCompany ? ROUTES.AUTO_EXTERNAL : ROUTES.WELCOME),
      getLeadId: () => localStorage.getItem('publicAutofinLeadId'),
    },
  );
  registerApplication(
    'notFound',
    () =>
      System.import(MFs.NOT_FOUND) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.notFound,
    {
      domElementId: 'renderContainerId',
      product: {
        name: 'Auto',
        landingPageURL: process.env.REACT_APP_CREDITAS_AUTOFIN_LANDING_URL,
      },
    },
  );
  registerApplication(
    'mfWelcome',
    () => import('./microfrontends/mfWelcome/mfWelcome.lifecycle'),
    Activity.mfWelcome,
    {
      goTo: goToRoutes.WELCOME,
    },
  );
  registerApplication(
    'mfExternalAutoForm',
    () =>
      import(
        './microfrontends/mfExternalAutoForm/mfExternalAutoForm.lifecycle'
      ),
    Activity.mfExternalAutoForm,
    {
      goTo: goToRoutes.AUTO_EXTERNAL,
    },
  );
  registerApplication(
    'mfPersonForm',
    () => import('./microfrontends/mfFormBuroQuery/mfFormBuroQuery.lifecycle'),
    Activity.mfFormBuroQuery,
    {
      goTo: goToRoutes.PERSONAL_INFO,
    },
  );
  registerApplication(
    'mfAddressForm',
    () => import('./microfrontends/mfAddressForm/mfAddressForm.lifecycle'),
    Activity.mfAddressForm,
    {
      goTo: goToRoutes.ADDRESS_INFO,
    },
  );
  registerApplication(
    'mfAutoForm',
    () => import('./microfrontends/mfAutoForm/mfAutoForm.lifecycle'),
    Activity.mfAutoForm,
    {
      goTo: goToRoutes.AUTO,
    },
  );
  registerApplication(
    'mfFormIncome',
    () => import('./microfrontends/mfFormIncome/mfFormIncome.lifecycle'),
    Activity.mfFormIncome,
    {
      goTo: goToRoutes.INCOME,
    },
  );
  registerApplication(
    'mfFormNip',
    () =>
      System.import(MFs.NIP) as Promise<LifeCycles<Record<string, unknown>>>,
    Activity.mfNip,
    {
      domElementId: 'renderContainerId',
      productName: 'publicAutofin',
      productType: 'MX_AUTO_FIN',
      getLeadId: () => localStorage.getItem('publicAutofinLeadId'),
      goTo: goToRoutes.NIP,
      apiUrl: process.env.REACT_APP_AUTO_ORCHESTRATOR_URL + '/public',
      landingUrl: process.env.REACT_APP_CREDITAS_LANDING_URL,
    },
  );
  registerApplication(
    'mfDocumentsForm',
    () => import('./microfrontends/mfDocumentsForm/mfDocumentsForm.lifecycle'),
    Activity.mfDocumentsForm,
    {
      goTo: goToRoutes.DOCUMENTS,
    },
  );
  registerApplication(
    'mfStepper',
    () =>
      System.import(MFs.STEPPER) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfStepper,
    {
      baseUrl: BaseRoutes.AUTOFIN_AUTO,
      domElementId: 'renderStepperId',
      leadIdKey: 'publicAutofinLeadId',
      apiUrl: process.env.REACT_APP_AUTO_ORCHESTRATOR_URL + '/public',
    },
  );
  registerApplication(
    'mfRejectedThirtyDays',
    () =>
      import(
        './microfrontends/mfRejectedThirtyDays/mfRejectedThirtyDays.lifecycle'
      ),
    Activity.mfRejectedThirtyDays,
    {
      goTo: BaseRoutes.AUTOFIN_AUTO + ROUTES.PERSONAL_INFO,
    },
  );
  registerApplication(
    'mfRejectedPage',
    () =>
      System.import(MFs.REJECTED) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfRejectedPage,
    {
      domElementId: 'renderContainerId',
      productName: 'publicAutofin',
      goTo: goToRoutes.REJECTED_REASONS,
      getLeadId: () => localStorage.getItem('publicAutofinLeadId'),
    },
  );
  registerApplication(
    'mfPreQualification',
    () =>
      System.import(MFs.PRECALIFICATION) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfPreQualification,
    {
      goTo: goToRoutes.PRE_QUALIFICATION,
      domElementId: 'renderContainerId',
      productName: 'publicAutofin',
      apiUrl: process.env.REACT_APP_AUTO_ORCHESTRATOR_URL + '/public',
      getLeadId: () => localStorage.getItem('publicAutofinLeadId'),
    },
  );
  registerApplication(
    'mfIdentityVerification',
    () =>
      System.import(MFs.MATI) as Promise<LifeCycles<Record<string, unknown>>>,
    Activity.mfIdentityVerification,
    {
      domElementId: 'renderContainerId',
      productName: 'publicAutofin',
      productType: 'MX_AUTO_FIN',
      clientId: process.env.REACT_APP_MATI_CLIENT_ID,
      flowId: process.env.REACT_APP_MATI_FLOW_ID,
      goTo: goToRoutes.IDENTITY_VERIFICATION,
      getLeadId: () => localStorage.getItem('publicAutofinLeadId'),
      containerProps: {
        marginTop: '5%',
      },
    },
  );
  registerApplication(
    'mfSentDocuments',
    () => import('./microfrontends/mfSentDocuments/mfSentDocuments.lifecycle'),
    Activity.mfSentDocuments,
  );
  registerApplication(
    'mfImage',
    () => import('./microfrontends/mfImage/mfImage.lifecycle'),
    Activity.mfImage,
    {
      images: ArrayImg,
    },
  );
  registerApplication(
    'mfFooter',
    () => import('./microfrontends/mfFooter/mfFooter.lifecycle'),
    Activity.mfFooter,
  );
};
registerApplication(
  'main',
  () => import('./microfrontends/app.lifecycles'),
  (location) => Activity.main(location, isMeliCompany),
);
registerApps();
start();
setTimeout(
  () =>
    registerApplication(
      'mfSendLaterButton',
      () =>
        import(
          './microfrontends/mfSentLaterButton/mfSendLaterButton.lifecycle'
        ),
      Activity.mfSendLaterButton,
    ),
  200,
);
