import { BaseRoutes } from "./baseRoutes";
import { getSteps } from "./microfrontends/shared/request/steps.api";
import { ROUTES } from "./routes";

interface IndexableMf {
  NOT_FOUND: string;
  MATI: string;
  RECEIVED_REQUEST: string;
  STEPPER: string;
  REJECTED: string;
  PRECALIFICATION: string;
  NAV_BAR_SUPPORT: string;
  [key: string]: string;
}

export const MFs: IndexableMf = {
  NOT_FOUND: `https://assets.creditas.com/mx-notfound-mf/${process.env.REACT_APP_BUILD_ENV}/creditasmx-notfound-mf.js`,
  MATI: `https://assets.creditas.com/mx-mati-verification-mf/${process.env.REACT_APP_BUILD_ENV}/mx-mati-verification-mf.js`,
  RECEIVED_REQUEST: `https://assets.creditas.com/mx-received-request-mf/${process.env.REACT_APP_BUILD_ENV}/mx-received-request-mf.js`,
  STEPPER: `https://assets.creditas.com/mx-stepper-mf/${process.env.REACT_APP_BUILD_ENV}/mx-stepper-mf.js`,
  REJECTED: `https://assets.creditas.com/mx-rejected-page-mf/${process.env.REACT_APP_BUILD_ENV}/mx-rejected-page-mf.js`,
  PRECALIFICATION: `https://assets.creditas.com/mx-precalification-mf/${process.env.REACT_APP_BUILD_ENV}/mx-precalification-mf.js`,
  NAV_BAR_SUPPORT: `https://assets.creditas.com/mx-navbar-support-modal-mf/${process.env.REACT_APP_BUILD_ENV}/mx-navbar-support-modal-mf.js`,
  NIP: `https://assets.creditas.com/mx-pin-verification-mf/${process.env.REACT_APP_BUILD_ENV}/creditasmx-pin-verification.js`,
};

Object.keys(MFs).forEach((key) => {
  MFs[key] = MFs[key] + `?v=${Math.random()}`;
});


const receivedRequestProps ={
  goTo: {
    toContinue: BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS
  },
  productName: 'publicAutofin',
  domElementId: 'renderContainerId',
  getLeadId: () => localStorage.getItem('publicAutofinLeadId'),
  personalDocsToShow: ['INE', 'PROOF_INCOME', 'PROOF_ADDRESS'],
  loadingSteps: false
}

export const getReceivedRequestProps = () => {
  // Because customProps is not a promise function we can use reference value to update it
  if(!receivedRequestProps.loadingSteps){
    receivedRequestProps.loadingSteps = true;
    getSteps().request({}).then((res)=> {
      if(res.data?.find( (step: { id: string; }) => step.id == 'verificacion-identidad' )){
        receivedRequestProps.goTo.toContinue = BaseRoutes.AUTOFIN_AUTO + ROUTES.IDENTITY_VERIFICATION;
      }else {
        receivedRequestProps.goTo.toContinue = BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS;
      }
    }).catch(() => {
      receivedRequestProps.loadingSteps = false
    })
  }  
  return receivedRequestProps;
}
